#disclaimer p {
  font-size: 1.6rem;
  line-height: 1.5;

  .paragraph {
    margin: 1rem 0px;
    width: 100%;
  }
  .h3 {
    display: inline-block;
    width: 100%;
    margin-bottom: 1rem;
  }
  .h3:not(:first-child) {
    margin-top: 2rem;
  }
}
