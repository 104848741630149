.search__sidebar {
  .search__filters {
    .sr-jump {
      position: absolute;
      left: 100px;
    }
    .search-header__title {
      margin: 0;
    }
    .filter__title--less {
      margin-bottom: 0;
    }
    .study_level__modal {
      margin-bottom: 2rem;
    }
    .filter {
      border: none;
      padding: 0;
    }

    .filter:not(:first-child) {
      margin-top: 3rem;
    }
  }
  .handbook__mobile_filters_done {
    z-index: 10000;
  }
}
