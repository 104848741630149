.handbook__fake_table_header {
  @media screen and (max-width: 63.95em) {
    margin: var(--gap) calc(var(--gap) * -1) !important;
  }
  border-top: 1px solid #d9d9d9 !important;
  border-bottom: 1px solid #d9d9d9 !important;
  padding: 2rem 4rem !important;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  span,
  a:first-of-type {
    flex: 1;
  }
  span:last-of-type {
    flex: 2;
  }
}
