/* set grid-template-columns to be 9/3 for screen start from 1112px (e.g. iPad Pro) */
@media (min-width: 69.5em) {
  [data-template='standard'] #page-content {
    display: grid;
    grid-template-areas: 'primary-content primary-content primary-content primary-content primary-content primary-content primary-content primary-content secondary-content secondary-content secondary-content secondary-content';
    grid-template-columns:
      [start] repeat(9, minmax(0, 1fr)) [middle] repeat(3, minmax(0, 1fr))
      [end];
    grid-gap: 0 var(--gap);
    padding-top: var(-gap);
  }
}
