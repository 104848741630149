.accordion {
  max-width: 100%;
}

.accordion__toggle span {
  margin-right: 25px;
}

button.accordion__heading.accordion__toggle:focus-visible {
  outline: 2px solid black;
}
