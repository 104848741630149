@media print {
  .mobile_card {
    display: none;
  }
}

.mobile_card {
  background-color: #fff;
  padding: 4rem 2rem 2rem 2rem;
  margin: var(--gap) calc(var(--gap) * -1) !important;
  .mobile_card__block {
    line-height: 1rem;
    display: flex;
    h3 {
      flex: 1;
      text-align: right;
      margin-bottom: 2rem;
    }
    ul {
      margin-left: 1rem;
      margin-bottom: 2rem;
      flex: 2;
      li {
        margin: 0;
      }
    }
  }
}
