.handbook__breadcrumbs {
  ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    li {
      span {
        vertical-align: middle;
        &.handbook__breadcrumbs_separator {
          margin: auto 2vw 0 2vw;
          .ico {
            --ico-width: 18px;
          }
        }
      }
    }
  }
}

.handbook__stickyuitop {
  .handbook__landingpagelink {
    padding: 20px;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 1;
    a {
      text-decoration: none;
      --link-color: #4b4b4b;
    }
  }
  .handbook__applynow {
    display: inline-block;
    flex-grow: 1;
    .apply-button {
      padding: 20px;
    }
  }
  @media (max-width: 63.9375em) {
    border: 1px solid #f0f0f0;
    border-top: 0;
  }
}

/* to show box-shadow in mobile view - start from 1023px */
@media (max-width: 63.9375em) {
  .header__bottom {
    box-shadow: 0 0 30px rgb(0 0 0 / 10%);
  }
}
