.sidebar-button {
  display: flex;
  justify-content: flex-start;
  color: white;
  background-color: #5525e5;
  border-color: #5525e5;
  border-style: none;
  padding: 1rem;
  span:first-child {
    margin-right: 1.5rem;
  }
  span:nth-child(2) {
    padding: 5px 0px;
  }
  &.link {
    text-decoration: none;
    margin-top: 10px;
    align-items: left;
    font-weight: normal;
    border-width: 2px;
    color: rgb(255, 255, 255);
  }
  &.apply {
    display: block;
    text-align: center;
    padding: 15px;
    background-color: #1de57e;
    border-color: #1de57e;
    color: #000;
  }

  @media (min-width: 48em) {
    .ico {
      --ico-width: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
