.handbook__boolean {
  border-style: solid;
  border-width: 2px;
  border-radius: 40px;
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 28px;
  font-size: 12px;
  margin: 3rem 0 3rem 40px;
  background: #fff;
  &.and {
    border-color: var(--color-lime);
  }
  &.or {
    border-color: var(--color-deep-blue);
  }
}
