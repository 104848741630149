.handbook__version_select {
  padding-bottom: 1rem;
  .select__options ul.list {
    width: 100%;
    margin-top: 2px;
    border-radius: 0;
  }
  .handbook__version_option {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      height: 70px;
      width: 4px;
    }
    p {
      margin: 10px 0 10px 10px;
      line-height: 25px;
      .smaller {
        font-size: 16px;
      }
    }
  }

  .handbook__version_option__future::before {
    background-color: #c3d82e;
  }

  .handbook__version_option__current::before {
    background-color: #5525e5;
  }

  .handbook__version_option__phasingout::before {
    background-color: #b3b3b3;
  }

  .handbook__version_option__past::before {
    background-color: #b3b3b3;
  }
}
