$border-gray: var(--gray);
$border-blue: var(--color-deep-blue);
$border-lime: var(--color-lime);

.course_structure__header {
  display: flex;
  h2 {
    margin: 0;
    flex: 1;
  }
}

.course_structure {
  .handbook_course_structure_table {
    thead {
      border-top: 1px solid #d9d9d9 !important;
    }
  }
  .study_period__header {
    margin: 2rem;
  }
  .course_structure__parent {
    button {
      color: #4b4b4b;
      width: 100%;
      padding: 20px;
      text-align: left;
      outline: 0;
      position: relative;
      &::after {
        content: '';
        border-bottom: 2px solid $border-gray;
        border-right: 2px solid $border-gray;
        height: 13px;
        width: 13px;
        transform: rotate(45deg); // open
        display: block;
        position: absolute;
        top: 28px;
        right: 28px;
        margin: -4px;
        transform-origin: 66% 66%;
        transition: transform 0.5s ease;
      }
      &.open::after {
        transform: rotate(-135deg);
      }
      .course_structure__content {
        font-size: 2rem;
      }
      border-style: solid;
      border-width: 2px;
      transition: border 0.2s ease-in-out;
      border-color: #e8e8e8;
      &:hover {
        border-color: #dbdbdb;
      }
      &:focus {
        border-color: $border-gray;
      }
      &.year {
        background-color: white;
        &.open {
          border-color: $border-blue;
          &::after {
            border-color: $border-blue;
          }
        }
      }
      &.semester {
        background-color: #f3f3f3;
        border-color: #cecece;
        border-left: 5px solid #535353 !important;
        &:hover {
          background-color: #d9d9d9;
          border-color: #9e9e9e;
        }
        &:focus {
          background-color: #e9e9e9;
          border-color: #c4c4c4;
        }
      }
      &.pathway {
        background-color: #f2f4dd;
        border-color: $border-lime;
        border-left: 5px solid $border-lime !important;
        &:hover {
          background-color: #d8e47e;
          border-color: $border-lime;
        }
        &:focus {
          background-color: #ecf1c6;
          border-color: $border-lime;
        }
      }
      &.unit {
        background-color: #efecf8;
        border-color: #b49ff1;
        border-left: 5px solid $border-blue !important;
        &:hover {
          background-color: #cfc2f4;
          border-color: $border-blue;
        }
        &:focus {
          background-color: #e0d9f6;
          border-color: $border-blue;
        }
      }
    }
  }

  table th:first-child {
    width: 25%;
  }
  table th:last-child {
    width: 15%;
  }

  .course_structure__child {
    margin-top: 5px;
    padding: 0;
    overflow: hidden;
  }
}
