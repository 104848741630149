.handbook__list_with_header__header {
  margin: 0 0 4px 0;
  font-weight: normal;
  font-size: 1.4rem;
  color: #717171;
}

.handbook__list_with_header__list {
  list-style-type: none;
  padding: 0;
  li {
    margin: 3px 0;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.3;
    p {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.3;
    }
  }
}
