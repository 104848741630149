.handbook__card_group {
  .card {
    margin-bottom: 35px;
    --card-bg-color: white;
    .inner {
      padding: var(--gap);
      .card__title {
        h2 {
          margin: 0 0 14px 0;
        }
      }
      .card__content {
        margin: 0;
        .card__links {
          margin: 0;
        }
        p {
          a {
            font-weight: normal;
            --link-color: var(--color-magenta-dark);
            --link-color-active: var(--color-deep-blue);
          }
        }
      }
      .card__links {
        a {
          font-weight: normal;
        }
      }
      .ico {
        margin-bottom: 0;
      }
    }
  }
}
