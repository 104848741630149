.search-page {
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding: var(--gap);
  #page-content {
    display: block;
  }
}
