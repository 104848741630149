.search-card {
  display: inherit;
  .search-card__description {
    p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .search-card__title-wrap {
    .search-card__title {
      a {
        font-size: 0.8em;
        span {
          font-weight: normal;
        }
      }
    }
  }
}
