.handbook__read_more_paragraph {
  &.truncate {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    &.show_more {
      -webkit-line-clamp: 8;
    }
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 0 var(--gap);
  }
  p {
    margin: 0 0 var(--gap);
    margin-block-end: 0px;

    .paragraph {
      margin: 1rem 0px;
      width: 100%;
    }
    .h3 {
      display: inline-block;
      width: 100%;
      margin: 1.5rem 0px 1rem 0px;
    }
  }
}
.btn-container {
  margin-top: 1.5rem;
}
