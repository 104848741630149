@media (max-width: 32em) {
  #useful-links {
    .link-list {
      list-style-type: none;
      padding: 0;
      font-size: 1.4rem;
      a {
        text-decoration-color: #000;
      }
    }
  }
}
