.autocomplete-list {
  li {
    padding: 0 !important;
    .autocomplete-navlink {
      display: inline-block;
      text-decoration: none;
      width: 100%;
      padding: 1.2rem var(--gap);
    }
  }
}
