.handbook__button-group {
  display: flex;
  button {
    flex: 1;
    padding: 0;
    margin: 0 2px;
    font-weight: normal;
  }
  button:focus-visible {
    outline: 2px solid black;
  }
}
