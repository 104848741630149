.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  z-index: 1000;
  backdrop-filter: blur(3px);
  background-color: rgba(179, 179, 179, 0.45);
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.ReactModal__Content {
  box-shadow: 0 0 30px rgb(0 0 0 / 7%);
  border-width: 0;
  line-height: 1.5;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  display: inline-flex;
  flex-direction: column;
  .handbook__modal_header {
    width: 100%;
    display: flex;
    padding: 2rem 1.5rem 2rem 6rem;
    background-color: #fff;
    h3 {
      margin: 3px 0 0 0;
      flex: 1;
    }
    button {
      width: 32px;
      height: 32px;
      padding: 0px;
      margin: auto 0;
    }
  }
  .handbook__modal_content {
    margin: 15px 15px 15px 0;
    padding: var(--gap-element) var(--gap-element) var(--gap-element) 6rem;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      display: none;
    }
  }
}
