@media print {
  .page-container {
    header.header__top,
    header.header__bottom > div > .header__utilities,
    header.header__bottom > div > nav {
      display: none;
    }
    header.header__bottom {
      position: relative;

      .contained > .header__logo_printable {
        line-height: 1.3rem;
        display: flex;
        margin-right: 10px;
        align-self: center;
        max-width: 206px;
      }

      .contained > .header__logo {
        display: none !important;
      }
    }
    a[href]:after {
      content: ' (' attr(href) ')';
    }
  }
}
