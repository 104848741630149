.handbook__location_select {
  .nice-select.select__options {
    margin-top: 4rem;
  }

  .select__options ul.list {
    width: 100%;
    margin-top: 2px;
    border-radius: 0;
  }

  .select__options li.option {
    margin: 0;
    padding: 1.5rem;
    display: flex;
    align-items: center;
  }

  .select__options li.option:before {
    content: '';
    display: inline-flex;
    width: 64px;
    height: 32px;
    border: 1px solid #e8e8e8;
    margin-right: 1.5rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .select__options li.option:first-child {
    display: none;
  }

  .select__options li.option.selected {
    font-weight: 400;
  }

  .select__options li.option[data-value='Canning College']::before,
  .select__options li.option[data-value='Curtin Kalgoorlie']::before,
  .select__options li.option[data-value='Curtin Perth']::before,
  .select__options li.option[data-value='Geraldton University Centre']::before,
  .select__options li.option[data-value='Great Southern TAFE Albany']::before,
  .select__options li.option[data-value='Margaret River Campus']::before,
  .select__options li.option[data-value='Muresk Institute']::before,
  .select__options li.option[data-value='Navitas English Perth']::before,
  .select__options li.option[data-value='Perth City']::before,
  .select__options li.option[data-value='Pilbara Institute']::before,
  .select__options li.option[data-value='Pilbara Universities Centre']::before,
  .select__options li.option[data-value='Tuart College']::before,
  .select__options li.option[data-value='UWA Albany Centre']::before,
  .select__options li.option[data-value='Curtin OUA']::before {
    background-image: url(flags/au.svg);
  }

  .select__options li.option[data-value='Curtin University Dubai']::before {
    background-image: url(flags/ae.svg);
  }

  .select__options li.option[data-value='Curtin University Malaysia']::before {
    background-image: url(flags/my.svg);
  }
  .select__options li.option[data-value='Curtin Mauritius']::before {
    background-image: url(flags/mu.svg);
  }
  .select__options li.option[data-value='Curtin Singapore']::before {
    background-image: url(flags/sg.svg);
  }

  .select__options li.option[data-value='Nanjing Audit University']::before,
  .select__options li.option[data-value='Dongbei Uni Econs Fin China']::before,
  .select__options li.option[data-value='Nanjing Uni Jinshen College']::before,
  .select__options
    li.option[data-value='South China Normal University']::before,
  .select__options li.option[data-value='Yanshan University']::before,
  .select__options li.option[data-value='Zhongnan Uni of Econ & Law']::before {
    background-image: url(flags/cn.svg);
  }

  .select__options
    li.option[data-value='HKU SPACE Island East Campus']::before {
    background-image: url(flags/hk.svg);
  }

  .select__options li.option[data-value='SEAMEO RETRAC']::before {
    background-image: url(flags/vn.svg);
  }

  .select__options li.option[data-value='Sri Lanka Inst Info Tech']::before {
    background-image: url(flags/lk.svg);
  }

  .select__options li.option[data-value='edX']::before {
    background-image: url(flags/us.svg);
  }

  .select__options.open {
    border-color: #5525e5;
    &::after {
      border-color: #5525e5;
    }
  }

  .locations__item {
    margin-top: 4rem;
  }

  .locations__years.two-or-more {
    display: flex;
    flex-wrap: wrap;
  }

  .locations__year {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .locations__year .locations__period,
  .locations__year h3 {
    margin-bottom: 1.5rem;
  }

  .locations__year * {
    margin-bottom: 0;
  }

  .locations__mode {
    list-style: none;
    padding: 0;
  }
}
