.search-by-query {
  margin-bottom: 0;
  .search-input {
    cursor: text !important;
    height: 100px;
    font-size: 14px;
    padding: 10px;
    border: 1px solid #f7f7f7;
    box-shadow: 0 0 var(--gap-half) rgba(0, 0, 0, 0.175);
    &::placeholder {
      color: var(--gray--dark);
    }
    &:hover {
      border-color: var(--color-deep-blue);
    }
  }
  .search-submit {
    outline: none;
    &:disabled {
      cursor: default;
    }
  }
}
