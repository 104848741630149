$mobile-max-width: 64em;

.offering-search {
  text-align: center;
  padding: var(--gap) 0;
  margin-bottom: 10px;
  background-color: var(--gray--light);
  h1 {
    font-style: italic;
    margin: var(--gap-half) 0 0 0;
  }
  p {
    max-width: 50vw;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: $mobile-max-width) {
      max-width: 90vw;
    }
  }
  .search-form {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    font-family: proxima-nova, Helvetica, Arial, sans-serif;
    font-display: swap;
  }
  .handbook__button-group {
    max-width: 480px;
    margin: var(--gap-half) auto;
    @media screen and (max-width: $mobile-max-width) {
      margin: var(--gap) auto;
    }
    button {
      @media screen and (max-width: $mobile-max-width) {
        font-size: 14px;
      }
      &.inactive {
        background-color: #fff;
        color: var(--text-color);
      }
    }
  }
}
