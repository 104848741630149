.handbook__radio_group {
  .search-by-query {
    margin: 20px 0 20px 0;
    .search-input {
      font-size: 14px;
      padding: 10px;
      height: 40px;
      border-radius: 6px;
      border: 2px solid var(--gray--light);
      box-shadow: none;
    }
    span {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 3px;
      svg {
        width: 17px;
      }
    }
  }

  .radio_group__scrollable {
    overflow-y: auto;
    max-height: 400px;
  }
  .default_value {
    margin-right: 10px;
  }
  .filter__option-wrap {
    margin-right: 10px;
    margin-bottom: 0;
    padding-right: 20px;
    .filter__option_label {
      font-size: 1.6rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: normal;
      &.selected {
        &::before {
          top: 50%;
          content: '';
          position: absolute;
          display: block;
          transition: 0.15s ease;
          transform: translateY(-50%);
          right: 0;
          width: 12px;
          height: 12px;
          background: #5525e5;
          border-radius: 50%;
        }
      }
    }
  }
}
