@media (min-width: 100em) {
  [data-template='standard']
    #page-content
    .content-container
    > .block:not(.block--no-indent),
  [data-template='standard'] #page-content .content-container > :not(.block) {
    margin-left: 12.5%;
    margin-right: 0;
  }
}
