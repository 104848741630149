@media (max-width: 63.99em) {
  .outline__links {
    .outline__links-wrap {
      padding: 1rem;
    }
    .link-list li:first-child {
      display: inline-block;
    }
    .link-list a {
      text-align: left;
      .ico {
        --ico-width: 22px;
        margin: 0px 5px 0px 0px;
      }
    }
  }
  .flex_style {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span:first-child {
      margin-right: 18px;
    }
    span:nth-child(2) {
      padding: 5px 0px;
    }
  }
}

@media (max-width: 32em) {
  .outline__links {
    .outline__links-wrap {
      .link-list li:last-child {
        width: 20%;
      }
    }
  }
}
