.nice-select .option:hover {
  margin: 15px 0px;
  padding: 0px 15px;
}

.select__options {
  width: 100%;
  border: 2px solid #e8e8e8;
  border-radius: 0;
  padding: 1.5rem;
  height: unset;
  float: unset;
  white-space: pre-wrap;
  &::after {
    height: 13px;
    width: 13px;
    right: 25px;
    top: 28px;
    transition: transform 0.5s ease;
  }
  &.open {
    border-color: #5525e5;
    &::after {
      border-color: #5525e5;
    }
  }
}

.select__options:after .select__options ul.list {
  width: 100%;
  margin-top: 2px;
  border-radius: 0;
}

.select__options li.option {
  margin: 1.5rem;
  padding: 0;
  display: flex;
  align-items: center;
}

.select__options li.option:first-child {
  display: none;
}

.select__options li.option.selected {
  font-weight: 400;
}
