.handbook__subheader {
  font-weight: 600;
  padding-bottom: 1rem;
  small {
    font-size: min(3.5vw, 90%);
    margin-left: 3%;
    font-weight: normal;
  }
  .smaller {
    font-size: min(3vw, 70%);
    font-weight: normal;
  }
}
