.loading {
  &.top {
    &::after {
      top: calc(10% - 15px);
    }
  }
  &::after {
    top: calc(50%);
  }
}

.handbook__disabled_input {
  opacity: 0.5;
  pointer-events: none;
}

.handbook__button_as_link {
  background: none;
  border: none;
  text-decoration: underline;
  padding: 0;
  &:hover,
  &:focus {
    color: var(--color-magenta-dark);
  }

  &.pink {
    color: var(--color-magenta-dark);
  }
}

.handbook__with_scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray--lighter);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray);
    border-radius: 10px;
  }
}
