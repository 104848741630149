.handbook__location_and_delivery {
  display: flex;
  h2 {
    margin: 0;
  }
  div {
    flex: 1;
    h3 {
      margin: 20px 0 0 0;
    }
    p {
      margin: 0;
    }
  }
}
#page-content .content-container > p {
  max-width: 100%;
}

article.content-container {
  .handbook__card_group {
    .card {
      display: none;
    }
  }
}
