.handbook__rule_block {
  @media screen and (max-width: 63.95em) {
    &.first {
      margin: var(--gap) calc(var(--gap) * -1) !important;
    }
  }
  &.white-background {
    padding: 1rem 0 2.5rem 0;
    background-color: #fff;
  }
  .rule_subheading {
    margin-bottom: 10px;
    padding: 2.5rem 4rem 0 4rem;
    &.nested {
      padding-top: 0;
    }
    p {
      font-size: min(4vw, 18px);
      margin-bottom: 5px;
    }
    span {
      font-size: min(3.5vw, 16px);
    }
  }
  .magenta_text {
    color: var(--color-magenta-dark);
  }
  ul {
    padding: 0;
    &.first-level {
      padding: 1rem 0;
      background-color: #fff;
    }
    li {
      list-style-type: none;
      padding: 0;
      .rule_offering {
        display: flex;

        line-height: 1.5;
        font-size: 14px;
        @media screen and (min-width: 500px) {
          font-size: calc(12.9373px + 0.21254vw);
        }
        @media screen and (min-width: 1441px) {
          font-size: 16px;
        }

        padding: 1rem 4rem;
        span,
        a:first-of-type {
          flex: 1;
        }
        span:last-of-type {
          flex: 2;
        }
        a:last-of-type {
          flex: 2;
        }
      }
    }
  }
}
