.card__title {
  margin-top: 1rem;
}

@media (max-width: 32em) {
  #unit-details-info {
    h3 {
      margin-top: 1rem;
    }
  }
}
