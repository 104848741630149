table {
  thead,
  th {
    border-top: none !important;
    border-bottom: 1px solid #d9d9d9 !important;
    padding: 2rem 2rem !important;
  }
  td {
    padding-top: 2rem;
    padding-bottom: 0;
    &:first-child {
      padding-left: 2rem;
    }
  }
}
