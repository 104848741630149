@media print {
  @page {
    size: A4;
    margin: 14mm;
  }
  .block-heading {
    margin-top: var(--gap);
  }

  .page-container {
    div.handbook__version_select {
      .select__options {
        &::after {
          display: none;
        }
        ul.list {
          width: 100%;
          margin-top: 2px;
          border: none;
          opacity: 1;
          pointer-events: auto;
          transform: scale(1) translateY(0);
          display: block;
          position: relative;
          background-color: rgb(245, 245, 245);
          margin-bottom: 0px;

          li {
            margin: 0px;
          }
        }
      }
      .nice-select {
        background-color: rgb(245, 245, 245);
        border: none;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;

        .list {
          box-shadow: none;

          .option {
            line-height: 30px;
            min-height: 30px;
          }
        }
      }
      .handbook__version_option {
        &::before {
          content: '';
          position: relative;
          left: 0;
          height: 70px;
          width: 4px;
        }
        p {
          margin: 0px;
          line-height: 25px;
        }
      }

      .handbook__version_option__future::before {
        background-color: none;
      }

      .handbook__version_option__current::before {
        background-color: none;
      }

      .handbook__version_option__past::before {
        background-color: none;
      }
    }
    #page-content > article.content-container {
      > .handbook__subheader {
        margin-bottom: 0.5em;
      }
      > .handbook__version_select {
        margin-top: 0px;
        margin-bottom: 0px;
      }
      .handbook__card_group {
        .card {
          display: flex;
          flex-direction: row;
          margin-left: 0px;
          .inner {
            padding: 25px 0 0 0;
            .handbook__list_with_header__list {
              margin-bottom: 25px;
            }
          }
        }
      }
      h2.loc-delivery-header {
        margin-top: 2em;
      }
    }
    aside.no-masthead {
      .handbook__card_group {
        .card {
          display: none;
        }
      }
    }
    .handbook__card_group {
      .card {
        display: block;
        margin-bottom: 0px;
      }
    }
    .handbook__read_more_paragraph.truncate {
      display: block;
    }
    div.button.outline__more {
      display: none;
    }
    section.block.accordions {
      div.accordion__content.content {
        display: block !important;
      }
    }
    #course_structure__expand_collapse_all {
      display: none;
    }
    .course_structure__parent {
      button {
        &::after {
          transform: rotate(-135deg);
        }
        &.year {
          border-color: var(--color-deep-blue);
          &::after {
            border-color: var(--color-deep-blue);
          }
        }
      }
    }
    .course_structure__child[style] {
      height: auto !important;
      & > div {
        display: block !important;
      }
    }
    .ico.ico-chevron-fat {
      &.ico--small.ico--gray,
      &.ico--deep-blue {
        display: none;
      }
    }

    #help-component {
      display: none;
    }
    footer {
      display: none;
    }
    .outline__links {
      display: none;
    }
  }
}
