@media (min-width: 64em) {
  [data-template='standard'] .support .support__wrap {
    text-align: center;
    grid-column: 2/6;
  }
}

@media screen and (min-width: 1441px) {
  [data-template='standard'] .support .support__title {
    font-size: 50px;
  }
}
